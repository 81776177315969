import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      arrows: false,
      autoplay: true,
      infinite: true,
      draggable: false,
    },
    homeGallery: {
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      infinite: true,
      arrows: false,
      appendDots: $('.section__dots-wrapper'),
      asNavFor: '.section--home-hero .section__gallery-numbers',
    },
    homeGalleryIndex: {
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      arrows: false,
      asNavFor: '.section--home-hero .section__gallery',
      vertical: true,
    },
    homeSolutions: {
      arrows: false,
      infinite: true,
      variableWidth: true,
    },
    homeBrands: {
      arrows: false,
      infinite: true,
      variableWidth: true,
      centerMode: true,
      swipeToSlide: true,
    },
    project: {
      infinite: false,
      draggable: false,
      // appendArrows: $('.preview__arrows-wrapper'),
    }
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })

  $("a[href='#']").click(function(e) {
    e.preventDefault()
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a[href*="#"]:not([href="#"])');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })

  $('.menu-item-has-children > a').on('click', function(e) {
    $(this).parent().toggleClass('active')
    $(this).next().slideToggle(200)
  }) 
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* PROJECT PREVIEW HOVER
  ----------------------------- */
export const projectHover = () => {
  $('.preview--project').each(function() {
    $(this).find('.preview__image-wrapper').hover(function() {
      $(this).parents('.preview__wrapper').toggleClass('is-hovered')
    })
  })
}

/* COMPANY ACTIVE MENU ANCHORS
  ----------------------------- */
export const companyActiveMenu = () => {
  const sections = [
    { trigger: "#company-skills", menuItem: "#menu-item-214" },
    { trigger: "#company-phases", menuItem: "#menu-item-213" },
    { trigger: "#company-skills", menuItem: "#menu-item-296" },
    { trigger: "#company-phases", menuItem: "#menu-item-295" }
  ]

  sections.forEach(section => {
    ScrollTrigger.create({
      trigger: section.trigger,
      start: "top top+=20%",
      end: "bottom bottom-=20%",
      onEnter: () => document.querySelector(section.menuItem).classList.add("current-menu-anchor"),
      onLeave: () => document.querySelector(section.menuItem).classList.remove("current-menu-anchor"),
      onEnterBack: () => document.querySelector(section.menuItem).classList.add("current-menu-anchor"),
      onLeaveBack: () => document.querySelector(section.menuItem).classList.remove("current-menu-anchor"),
      // markers: true
    })
  })
}

/* PROJECT OPEN MODAL TO SPECIFIC SLIDE
  ----------------------------- */
export const projectOpenModalSlide = () => {
  $('.preview__expand').on('click', function () {
    const expandBtn = $(this)
    const currentModal = expandBtn.attr('href')
    const currentSlide = expandBtn.parents('.preview--project').find('.slick-current').attr('data-slick-index')

    $(currentModal).modal()
    $(currentModal).find('.preview__modal-gallery').slick({ infinite: true })
    $(currentModal).find('.preview__modal-gallery').slick('slickGoTo', currentSlide)
  })

  $('.preview__modal-gallery-wrapper').on($.modal.AFTER_CLOSE, function (event, modal) {
    $(this).find('.preview__modal-gallery').slick('unslick')
  })
}