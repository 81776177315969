import $ from 'jquery'
import * as project from './functions'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  project.projectHover()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "page-template-company":
        project.companyActiveMenu()
        break
      case "tax-project_category":
        project.projectOpenModalSlide()
        break
      default:
        $('#loader').fadeOut(500)
    }
  })
}
